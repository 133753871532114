<template>
  <div class="warp">
    <div class="cententbox">
      <!-- <div class="persontextbox disflex flexspan">
                <span class="persontext">SOS位置</span>
            </div> -->
      <div class="heaedercl disflex flexal">
        <div>
          <div class="fhText mouse" @click="backbtn">{{ $t('message.refund') }}</div>
        </div>
        <div class="head">
          <div></div>
          <!-- <div class="flex">
            <div class="flex head_dropDown">
              <span>群组</span>
              <el-select class="head_select" v-model="groupId" placeholder="请选择群组">
                <el-option v-for="item in group" :key="item.Cg_ID" :label="item.Cg_Name" :value="item.Cg_ID">
                </el-option>
              </el-select>
            </div>
            <div class="flex head_dropDown">
              <span>发送人</span>
              <el-select class="head_select" v-model="userId" placeholder="请选择发送人">
                <el-option v-for="item in user" :key="item.User_ID" :label="item.User_Name" :value="item.User_ID">
                </el-option>
              </el-select>
            </div>
            <div class="head_search head_dropDown" @click="search()">
              搜索
            </div> -->
          <div class="head_but mouse" v-if="isShow" @click="beginEnd">
            {{ $t('message.dealwith') }}
          </div>
          <!-- </div> -->
        </div>
      </div>
      <!-- <div class="baidu-api" v-show="mapType == 'baidu'" id="allmap"></div> -->
      <div class="baidu-api" v-show="mapType == 'Wgs84'" id="googlemap"></div>
    </div>
  </div>
</template>
<script></script>
<script>
  import {queryUserName,queryGroupName} from "@/administration/history.js";
  import axios from "axios";
  import qs from 'qs';
  import EventBus from "@/eventBus/eventBus.js";
  import beasconfig from '@/api/beasconfig.js';
  export default {
    data() {
      return {
        sosDetails: {},//经纬度数据
        datasoss:{},
        address:'',
        userInfo:{}, //用户信息
        group:[     //群组
                {
                    value:1,
                    label:'群组1'
                }
            ],
        groupId:'',   //群组id
        user:[     //用户
                {
                    value:1,
                    label:'群组1'
                }
          ],
         userId:'',   //用户id
         isShow:false,
         routeName:null,
         timeNum:0,  //判断谷歌地图是否加载完成
         gooleWc:false,    //谷歌地图是否加载完成
         markerLabel:null,
         timeMarker: null,
         language: localStorage.getItem('languageStorage') || 'en',
         gooleCover: [],  //覆盖物数组
         maps:null,
         mapType: 'Wgs84'
      };
    },
    beforeDestroy() {
      this.clearTime();
    },
    mounted() {
        this.into(1);
    },
    methods: {
      cutMap(type) {   //切换地图
        if (this.mapType != type) {
          this.mapType = type;
          this.clearTime();
          if (this.gooleWc == false) {  //判断地图是否有加载出来
            this.initMap();
          }
          if (this.sosUser.sos_status > 0 && this.sosUser.isStatus > 0) {
                  this.getTrajectoryMethod();
          }else{
                  let bool = this.sosDetails && this.sosUser.sos_status == 0 && this.sosUser.isStatus != 1;
                  const { lon, lat } = bool ? this.mapType == "Wgs84" ? this.sosDetails.wgs84 : this.sosDetails.baidu : {
                    lat: 38.903804230612835,
                    lon: -77.0365677652717
                  };
                  this.setMarker([lat,lon],1);
                  this.timeMarker = setInterval(() => {
                    if (this.sosUser.sos_status <= 0) {
                      this.setMarker([lat,lon]);
                    }else{
                      this.clearTime();
                    }
                  }, 5000);
          }
        }
      },
      into(type){
            let that = this;
            that.sosDetails = that.sosUser.sos_location_at ? JSON.parse(that.sosUser.sos_location_at) : ''; //经纬度
            let bool = that.sosDetails && that.sosUser.sos_status == 0 && that.sosUser.isStatus != 1;
            const { lon, lat } = bool ? that.mapType == "Wgs84" ? that.sosDetails.wgs84 : that.sosDetails.baidu : {lat: 38.903804230612835,lon: -77.0365677652717};
            if(type == 1){
              that.initMap([lat,lon]);
            }else{
              that.setMarker([lat,lon])
            }
      },
      initMap(pt){
          if (window.googleMap) {  //window.googleMap有值就代表地图API已经加载完成
            this.timeNum = 0;
              try {
                this.maps = new google.maps.Map(document.getElementById("googlemap"), {
                  zoom: 10,
                  mapTypeControl: false,
                  fullscreenControl: false,
                  streetViewControl: false,
                  mapTypeId: google.maps.MapTypeId.ROADMAP,
                  //地图中心点，这里我以第一个数据的经纬度来设置中心点
                  center: { lat: pt[0], lng: pt[1] }, //{ lat: 39.91600806946438, lng: 116.41309823809416 }, //{ lat: pt[0], lng: pt[1] },
                  disableDefaultUI: false,
                  zoomControl: false,
                  maxZoom: 17,  //18
                  minZoom:3,
                  disableDoubleClickZoom: true,
                  draggableCursor:'auto'
                });
                this.mapTrafficLayer = new google.maps.TrafficLayer();
                this.gooleWc = true;
                if(this.mapType == "Wgs84"){
                  if (this.sosUser.sos_status > 0 && this.sosUser.isStatus > 0) {
                    this.getTrajectoryMethod();
                  } else {
                    this.setMarker(pt);
                    this.timeMarker = setInterval(() => {
                      if (this.sosUser.sos_status <= 0) {
                        this.setMarker(pt);
                      }else{
                        this.clearTime();
                      }
                    }, 5000);
                  }
                }
              } catch (error) {
                this.gooleWc = false;
                console.log("需要翻墙")
              }
        } else {
          setTimeout(() => {
            this.timeNum += 1;
            if (this.timeNum <= 10) {
              this.initMap(pt);
            }
          }, 1000)
        }
      },
      /*创建定位*/
     async setMarker(point, type){
        // var address = ''; //保存经纬度转地址
        if (this.markerLabel) {
            this.markerLabel.setMap(null);
        }
          // let latLngData = `${point[0]},${point[1]}`;
          // let m = global.getMapAddress(latLngData);
          // await m.then((result) => {
          //           address = this.sosDetails?result:'';
          //           // console.log('返回值:', result);
          // }).catch((error) => {
          //           console.log('发生错误:', error);
          // });
          var params = {
            Uids: this.sosUser.sos_from_id, //再线成员用户id
            CorrdinateType: this.mapType
          };
      axios
        .post(
          beasconfig.gpsUrlapi + "/shanli/gps/api/locations/LastLocation?",
          qs.stringify(params),
          {
            headers: beasconfig.tokenSl
          }
        ).then(res =>{
          let data = res.data.Data;
          let Rtimestamp = "";
          let address = this.sosDetails?point:[];
          if (data.length > 0) {
            Rtimestamp = this.$moment(Number(data[0].Rtimestamp)).format("YYYY-MM-DD HH:mm:ss");
            address = [data[0].Lat,data[0].Lng];
          }
          var contentHtml = '';
                  contentHtml += `<div class="baiduinfo_box" style="${this.mapType == "Wgs84"?'position: fixed;':''}" ><div class="baiduinfo" >`;
                  contentHtml += `<div><span class="boldText">${this.$t('message.Name')}:</span> ${this.sosUser.sendName }</div>`;
                  contentHtml += `<div><span class="boldText">${this.$t('message.group')}:</span> ${this.sosUser.Cg_Name == null || this.sosUser.Cg_Name == ""?this.$t('message.temporarygroup'):this.sosUser.Cg_Name}</div>`;
                  contentHtml += `<div class="flex-al htmls"><span class="boldText">${this.$t('message.Address')}:</span><div class="boldText1" > ${address.length > 0? address.join():''}</div></div>`;
                  // contentHtml += `<div class="flex-al htmls"><span class="boldText">${this.$t('message.Latitudeandlongitude')}:</span> <div class="boldText1" > ${point.join()}</div></div>`;
                  contentHtml += `<div><span class="boldText">${this.$t('message.Reportingtime')}:</span> ${this.sosUser.sos_start_time}</div>`;
                  contentHtml += `<div><span class="boldText">${this.$t('message.Positioningtime')}:</span> ${Rtimestamp}</div>`;
                  if(this.sosUser.sos_status == 0){
                    contentHtml += `<div class="boldText" >${this.$t('message.State')}: <span style="color: red;" >${this.$t('message.Unprocessed')}</span></div>`;
                  }else{
                    contentHtml += `<div class="boldText" >${this.$t('message.State')}: <span>${this.$t('message.Processed')}</span></div>`;
                  }
                  contentHtml += `<div class="hint boldText1">${this.$t('message.Warmreminder')}</div>`;
                  contentHtml += '</div> </div>';
            if(this.mapType == "Wgs84"){
                let googleAnchor = {
                  top: this.sosDetails ? 260 : 80,
                  left:145
                };
                var position = new google.maps.LatLng(point[0], point[1]);
                this.markerLabel = new MarkerWithLabel({
                    position: position,
                    icon: {
                      url: this.sosDetails?require('../../assets/history/ckSOS.png'):' ',
                      // 设置图片的大小和偏移量
                      // size: new google.maps.Size(32, 32),
                      // anchor: new google.maps.Point(16, 15)
                    },
                    map: this.maps, //地图实例
                    labelContent:contentHtml, //label的内容
                    // 调整框框
                    labelAnchor: new google.maps.Point(googleAnchor.left, googleAnchor.top),
                    labelStyle: {
                      padding: "0px",
                      color: '#ffffff',
                      // backgroundColor: 'rgb(103, 197, 197)',
                      // borderRadius: '5px',
                      fontWeight: '400',
                      opacity: 1
                    }
                });
                if (type == 1) {
                  this.maps.setCenter(position);
                }
            }
        })
      },
      /*返回群组列表*/
      backbtn() {
        let handleData = this.$store.state.handleData;
        let index = handleData.findIndex(item => item.sosContent.sosProcessorId == this.userInfo.User_ID);
        if(index >= 0){
          this.$store.state.handleData.splice(index,1);
        }
        if(this.$route.name == 'sosPosition'){
          if(this.$store.state.navId == 1){
            this.$router.push(`/intercom/baiduMap`);
          }else if(this.$store.state.navId == 2){
            this.$router.push(`/enclosure/enclosureMap`);
          }else if(this.$store.state.navId == 3){
            this.$router.push(`/promotion/videoInfo`);
          }else{
            this.$router.push(`/history/sosrecord`);
          }
        }else{
          this.$router.go(-1);
        }
      },
       getQueryGroupName(){  //查询群组下拉
            let that = this;
            let data = {
                uId:that.userInfo.id
            }
            queryGroupName(data).then(res =>{
                    if(res.data.code == 200){
                        let list = res.data.data;
                         list.unshift({
                                Cg_ID:-1,
                                Cg_Name:this.$t('message.pleaseselectagroup')
                        })
                        this.group = list;
                    }else {
                        that.$MessageWarning(res.data.msg);
                    }
            })
        },
        getQueryUserName(){ //获取用户下拉
            let that = this;
            let data = {
                uId:that.userInfo.id
            }
            queryUserName(data).then(res=>{
                   if(res.data.code == 200){
                        that.user = res.data.data;
                    }else {
                        that.$MessageWarning(res.data.msg);
                    }
            })
        },
        beginEnd(){ //处理结束
           let that = this;
          //  let handleData = this.$store.state.handleData;
          //  let row = handleData.find(item => item.sosContent.sosProcessorId == that.userInfo.User_ID);
          //  if(row){
          // this.$MessageWarning(`已被调度员（${row.sosContent.sosProcessorName}）处理`);
          //               that.isShow = false;
          //               that.sosUser.sos_status = 1;
          //               that.into(0);
          //     return
          //  }
          that.$store.state.ws.sosCloseAlarm(that.userInfo.User_ID, that.sosUser.sos_msg_id);
          EventBus.$emit("cloneSosInfo", that.sosUser.sos_msg_id);
          //  axios.put(`${beasconfig.sosUrlapi}/sos/mg/handle/beginEnd?sosMsgId=${that.sosUser.sos_msg_id}&uid=${that.userInfo.User_ID}`).then(res => {
          //               // console.log(res)
          //               if(res.data.code == 200){
          //                 that.isShow = false;
          //                 that.sosUser.sos_status = 1;
          //                 that.into(0);
          //               }
          //        }).catch(() =>{
          //           that.sosMsg(0);
          //        })
        },
        search(){   //搜索

        },
        sosMsg(type){   //提示
                axios.get(`${beasconfig.sosUrlapi}/sos/mg/detail?sosMsgId=${this.sosUser.sos_msg_id}`).then(res1 =>{
                             if(res1.data.code == 200){
                                  if(res1.data.data.sosStatus != 0){
                                    if(type == 0){
                                        this.$MessageWarning(`${this.$t('message.alreadydispatched')}（${res1.data.data.sosProcessorName}）${this.$t('message.dealwith')}`);
                                    }
                                    this.isShow = false;
                                    this.sosUser.sos_status = res1.data.data.sosStatus;
                                    this.into(0);
                                  }
                                  if(type == 2){
                                    this.into(0);
                                  }
                             }
                        })
        },
        clearTime() {
          if (this.timeMarker != null) {
            clearInterval(this.timeMarker);
            this.timeMarker = null;
          }
        },
            /*历史轨迹*/
        getTrajectoryMethod() {
          let startDateTamp = Number(this.sosUser.sos_start_time_stamp);
          let endDateTamp = Number(this.sosUser.sos_end_time_stamp);
          let vv1 = this.$moment(startDateTamp).format("YYYY-MM-DD HH:mm:ss");
          let vv2 = this.$moment(endDateTamp).format("YYYY-MM-DD HH:mm:ss");
          var params = {
            Uid: this.sosUser.sos_from_id,
            CorrdinateType: this.mapType,  // "baidu",百度地图  "Wgs84" 谷歌地图
            startDateTime: vv1,
            endDateTime: vv2,
            startDateTamp: startDateTamp, //时间戳13位
            endDateTamp: endDateTamp
          };
          // if (this.isOrientation != 'true') {
          //   params.positionType = 2
          // }
          axios.post(beasconfig.gpsUrlapi + '/shanli/gps/api/trace/gethistory?', qs.stringify(params), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          }).then(res => {
            console.log('轨迹数据', res.data.Data)
            if (res.data.Status == 1 && res.data.Data.length > 0) {
              var pots = [];
              var rectifyPoints = [];
              let that = this;
              res.data.Data.forEach(function (el) {
                if (el.Lng && el.Lat) {
                  rectifyPoints.push({
                    "coord_type_input": "bd09ll",
                    "latitude": el.Lat,
                    "longitude": el.Lng,
                    "loc_time": Number(el.Rtimestamp)/ 1000,
                    "speed": el.Speed
                  });
                    pots.push({ lat: el.Lat, lng: el.Lng },);
                }
              });
              that.drawHistroyTrace(pots,rectifyPoints);
            } else {
              this.$message({
                message: this.$t('message.user.no_data'),
                type: "warning",
                center: true,
                offset: 200
              });
            }
          })
        },
        // 绘制轨迹
        drawHistroyTrace(pois,dwArr) {
          // 先清除上次画的轨迹
          this.clearOverlays();//先清除上次画的轨迹
          var gps ={}; //找出相同的定位点
          dwArr.forEach((item,index) =>{
            var pint = item.loc_time;
            if(!gps[pint]){    //判断是否有这个数组
              var html = `<div class='bd_circular mouse_dw'><div id="dw${index}" class="ds_box ds_none">${this.$moment(item.loc_time*1000).format("YYYY-MM-DD HH:mm:ss")}<div class="big" style="bottom: -12px;" ><div class='small'></div></div></div></div>`;
              gps[pint]= [item];
              if(this.mapType == "Wgs84"){
                var position = new google.maps.LatLng(item.latitude, item.longitude);
                const markerLabel = new MarkerWithLabel({
                    id:index,
                    position: position,
                    icon: {
                      url: ' '
                    },
                    map: this.maps, //地图实例
                    labelContent: html, //label的内容
                    // 调整框框
                    labelAnchor: new google.maps.Point(4, 8),
                    labelStyle: {
                      padding: "0px",
                      position:'absolute',
                      // color: '#ffffff',
                      // backgroundColor: 'rgb(103, 197, 197)',
                      // borderRadius: '5px',
                      fontWeight: '400',
                      opacity: 1
                    },
                    labelZIndex:index,
                    zIndex:100000+index
                });
                  markerLabel.addListener("mouseover", () => {
                      this.showMarkerLabel(markerLabel.id,1);
                  });
                  markerLabel.addListener("mouseout", () => {
                      this.showMarkerLabel(markerLabel.id,0);
                  });
                  this.gooleCover.push(markerLabel)
              }
            }
          })
          this.drawRoute(pois);
          this.addMarker({
            lng: pois[0].lng,
            lat: pois[0].lat
          }, 'start');
          this.addMarker({
            lng: pois[pois.length - 1].lng,
            lat: pois[pois.length - 1].lat
          }, 'end');
        },
        // 绘制两点之间的路径
        drawRoute(pois) {
              const flightPath = new google.maps.Polyline({
                path: pois,
                geodesic: true,
                strokeColor: "#999",
                strokeOpacity: 1.0,
                strokeWeight: 6,
              });

              const flightPath2 = new google.maps.Polyline({
                path: pois,
                geodesic: true,
                strokeColor: "#4cdd25",
                strokeOpacity: 1.0,
                strokeWeight: 4,
              });
                flightPath.setMap(this.maps);
                flightPath2.setMap(this.maps);
                this.gooleCover.push(flightPath, flightPath2)
            this.setZoom(pois);
        },
        // 根据点的数组自动调整缩放级别
        setZoom(pois) {
          if (this.mapType == 'Wgs84') {
            if (this.maps) {
              var latlngbounds = new google.maps.LatLngBounds();  //根据点的数组自动调整缩放级别
              pois.forEach(item => {
                latlngbounds.extend(item);
              })
              this.maps.fitBounds(latlngbounds);
              this.maps.panToBounds(latlngbounds);
            }
          }
        },
        showMarkerLabel(id,type){  //显示盒子
            var t = document.getElementById(`dw${id}`);//选取label为百度baidu的id进行隐藏
            t.style.display =type == 1?'block':'none';// 以块级样式隐藏
        },
        // 创建覆盖物
        addMarker(point, type) {
          var pos = point;
          if (pos) {
            var iconSrc = '';
            if (type == 'start') {
                  iconSrc = require(this.language == 'zh' ? '../../assets/map/sos_start.png' : '../../assets/map/sos_start_hw.png');
                }
                if (type == 'end') {
                  iconSrc = require(this.language == 'zh' ? '../../assets/map/sos_end.png' : '../../assets/map/sos_end_hw.png');
                }
              var position = new google.maps.LatLng(pos.lat, pos.lng);
              // const marker =                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
              const marker = new google.maps.Marker({
                markerId: 'Marker1',
                position,
                map: this.maps,
                icon: {
                  url: iconSrc, //most point
                  size: new google.maps.Size(63, 73),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(16, 36),
                  scaledSize: new google.maps.Size(31.5, 36)
                }
              });
              this.gooleCover.push(marker)
          }
        },
        clearOverlays() {   //清除所添加覆盖物
          this.gooleCover.forEach(item => {
            item.setMap(null);
          })
          this.gooleCover = [];
        }
    },
    created() {
        this.sosUser = JSON.parse(this.$route.query.sosUser);
        this.isShow = this.sosUser.sos_status==0?true:false;
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        // this.getQueryGroupName();
        // this.getQueryUserName();
        // this.sosMsg();
    },
    beforeRouteEnter(to, from, next) {   //监听页面
        next(vm => {
            if(from.name != 'sosPosition' && from.name != null){
              vm.routeName = from.path;
            }
        })
    },
    watch: {
        '$store.state.handleData'(newVal) {
            //对数据执行操作
            let find = this.$store.state.handleData.find(item => item.sosContent.msgId == this.sosUser.sos_msg_id);
            if (newVal.length > 0) {
                      if(find){
                        this.sosMsg(1)
                      }
            }
        },
        $route(){
              this.sosUser = JSON.parse(this.$route.query.sosUser);
              this.isShow = this.sosUser.sos_status==0?true:false;
              // this.into(0);
              this.sosMsg(2);
        }
    }
  };
</script>

<style>
.flex-al {
  display: flex;
  position: relative;
}

.nowrap {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.baiduinfo_box {
  /* width: 280px; */
  height: 160px;
  /* position: absolute; */
  background-color: transparent !important;
  text-align: left;
}

.baiduinfo {
  color: #000;
  /* font-weight: bold; */
  width: 280px;
  font-size: 14px;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  padding: 10px;
  border-radius: 5px;
}

.boldText {
  font-weight: bold;
  font-size: 15px;
  min-width: 40px;
}

.boldText1 {
  flex: 1;
  white-space: break-spaces;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.baiduinfo>div {
  line-height: 18px;
  margin-bottom: 2px;
  font-weight: bold;
  word-break: break-all;
}

.hint {
  color: red;
  font-size: 12px;
}

.address {
  color: #303030;
}

.show {
  width: 300px;
  position: absolute !important;
  left: 290px !important;
  top: -5px;
  display: none;
  background-color: #fff;
  padding: 5px 10px;
  white-space: pre-wrap;
  /* border-radius: 5px; */
}

.htmls {
  position: relative;
}

.htmls:hover .show {
  color: #303030;
  opacity: 1;
  display: block;
}
</style>

<style lang="less" scoped>
.warp {
  height: 100%;

  .cententbox {
    height: calc(100%);

    .persontextbox {
      height: 42px;
      border-bottom: 1px solid #ddd;

      .persontext {
        display: inline-block;
        height: 41px;
        line-height: 41px;
        padding: 0 20px;
        font-size: 15px;
        color: #888;
        border: 1px solid #ddd;
        border-bottom-color: #fff;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background-color: #fff;
      }
    }

    .disflex {
      display: flex;
      flex-direction: row;

      &.heaedercl {
        justify-content: space-between;
        background: #f0f0f0;
        padding: 10px 0;
      }

      .fhText {
        padding: 12px 20px;
        color: #fff;
        background-color: #4d4d4d;
        border-radius: 5px;
        margin-left: 15px;
        font-size: 15px;
      }
    }
  }

  .baidu-api {
    width: 100%;
    // height: 200px;
    height: calc(100% - 64px);
  }

}

.head {
  flex: 1;
  background-color: #f0f0f0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  color: #5a5655;

  &_container {
    flex: 1;
    margin-left: 10%;
  }

  &_dropDown {
    font-size: 15px;
    color: #5a5655;
    margin-right: 20px;

    span {
      margin-right: 5px;
    }
  }

  &_select {
    width: 160px;
    height: 40px;
  }

  &_picker {
    width: 280px !important;
    text-align: center;
  }

  &_search {
    width: 70px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: url(/img/leftBg1.81e6fd48.png);
    background-size: 100% 100%;
    border-radius: 5px;
    color: #ffffff !important;
  }

  &_but {
    width: 110px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #327677;
    border-radius: 3px;
    color: #ffffff !important;
    margin-right: 10px;
  }
}


.flex {
  display: flex;
  align-items: center;
}
</style>